@import (reference) '~nav-frontend-core/less/_variabler';
@import (reference) '~nav-frontend-paneler-style/src/mixins';
@import (reference) '~nav-frontend-typografi-style';

.nav-veilederpanel {
  .panel-mixin();
  .typo-normal-mixin();

  position: relative;
  border-radius: 0.5rem;
  border: 2px solid @navGra20;
  min-height: 7.25rem;
  padding: 1.5rem;
  padding-left: 7rem;
  background: white;
  display: flex;
  align-items: center;
  text-align: left;

  &__content {
    width: 100%;
  }

  .nav-veileder {
    position: absolute;
    top: 1rem;
    left: 1rem;
  }

  &--plakat {
    padding: 2rem;
    padding-top: 8.25rem;

    .nav-veileder {
      left: 50%;
      margin-left: -3.125rem;
    }
  }

  &--kompakt:not(&--plakat) {
    padding: 1.5rem;
    padding-left: 3.5rem;
    margin-left: 2.5rem;

    .nav-veileder {
      left: -40px;
    }
  }

  &--kompakt&--plakat {
    margin-top: 3.125rem;
    padding: 2rem;
    padding-top: 4.25rem;

    .nav-veileder {
      top: -50px;
    }
  }

  &--suksess {
    border: 2px solid @navGronnLighten60;
  }

  &--advarsel {
    border: 2px solid @navOransjeLighten60;
  }

  &--feilmelding {
    border: 2px solid @redErrorLighten60;
  }
}
